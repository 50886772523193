// App.js

import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Routes, Route, Link } from 'react-router-dom';
import { ChakraProvider, Box, VStack, Link as ChakraLink, Heading } from '@chakra-ui/react';
import RecordTable from './RecordTable';
import RecordEmotionChart from './RecordEmotionChart';
import {Amplify, Auth} from 'aws-amplify';
import { getCurrentUser,fetchAuthSession  } from 'aws-amplify/auth';
import { withAuthenticator } from '@aws-amplify/ui-react';
import amplifyconfig from './amplifyconfiguration.json';
import '@aws-amplify/ui-react/styles.css';
import { generateClient } from 'aws-amplify/api';
import { listJournalData } from './graphql/queries';

Amplify.configure(amplifyconfig);
const client = generateClient();

function App() {
  const [userId, setUserId] = useState(null);
  const [journalData, setJournalData] = useState([])

  async function currentSession() {
    try {
      const session = await fetchAuthSession();
    } catch (err) {
      console.log(err); // エラーをログに出力
    }
  }

  async function fetchJournalData(userId) {
    try {
      const data = await client.graphql({query: listJournalData});
      const fetchedJournalData = data.data.listJournalData.items.filter(item => item.userId === userId);
      setJournalData(fetchedJournalData);
    } catch (error) {
      console.error("Error fetching journal data:", error);
    }
  }
  
  useEffect(() => {
    async function initializeData() {
      try {
        await currentSession(); // ユーザーのセッション状態を確認
        const userInfo = await getCurrentUser(); // 現在のユーザー情報を取得
        setUserId(userInfo.userId); // ユーザーIDを設定
      } catch (err) {
        console.error("Error initializing data:", err);
      }
    }
    initializeData();
  }, []);
  
  useEffect(() => {
    if (userId) {
      fetchJournalData(userId); // userIdが設定された後にデータをフェッチ
    }
  }, [userId]); // userIdが変更されたときにのみ実行
  

  console.log(userId)

  return (
    <ChakraProvider>
      <Router>
        <Box p={5}>
          <VStack spacing={4} align="stretch">
            <Box>
              <ChakraLink as={Link} to="/record-table" mr={4}>
                表の表示+エクセル出力
              </ChakraLink>
              <ChakraLink as={Link} to="/record-emotion-chart">
                チャート表示
              </ChakraLink>
            </Box>
          </VStack>
        </Box>

        <Routes>
          <Route 
            path="/" 
            element={
              <RecordEmotionChart
              journalData={journalData} 
              />} 
          />

          <Route 
            path="/record-table" 
            element={
              <RecordTable
                 journalData={journalData}
              />} 
          />

          <Route 
            path="/record-emotion-chart" 
            element={
              <RecordEmotionChart 
                 data={journalData}
              />} 
          />
        </Routes>
      </Router>
    </ChakraProvider>
  );
}

export default withAuthenticator(App);

