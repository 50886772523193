// RecordTable.js

import React, { useRef,useState, useEffect } from 'react';
import { Bar } from 'react-chartjs-2';
import { Chart as ChartJS, CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend, } from 'chart.js';
import useFetchJournalData from './useFetchJournalData';
import { Box, Text} from '@chakra-ui/react';
import './App.css'; 

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

function replaceNewlines(text) {
    return text.replace(/([。・\s]+)/g, '$1\n');
}

function createTooltipCallbacks(rawData) {
    return {
        // ...他のコールバック関数は変更なし...
        label: function(tooltipItem) {
            const emotionIndex = tooltipItem.datasetIndex; // 文字列変換を削除
            const dateData = rawData.find(d => d.date === tooltipItem.label);
            if (dateData) {
                const items = dateData.data[emotionIndex];
                // return items.map(item => [
                //     `項目: ${item.title}`,
                //     `メモ: ${item.text}`,
                //     `対象者: ${item.person}`,
                //     `感情: ${item.emotion}`
                // ]).flat();
                return items.map(item => {
                    // 各項目を変更し、特定の文字で改行を加える
                    const title = `項目: ${item.title}`;
                    const memo = `メモ: ${replaceNewlines(item.text)}`;
                    const person = `対象者: ${item.person}`;
                    const emotion = `感情: ${replaceNewlines(item.emotion)}`;
                    return [title, memo, person, emotion];
                }).flat();
            }
            return [];
        },
        afterBody: function(tooltipItems, data) {
              return ' ';
          }
    };
}


function sortFunction(a, b) {
    return a.emotionIndex - b.emotionIndex;
}

function RecordEmotionChart() {
    const { journalData, error, loading } = useFetchJournalData()
    const [processedData, setProcessedData] = useState([]);
    const labels = getLast30DaysDates(); 
    const [rawData, setRawData] = useState([]);
    const scrollContainerRef = useRef(null);

    console.log("✅✅✅", journalData)
    
    function getLast30DaysDates() {
        const dates = [];
        const today = new Date();
        for (let i = 29; i >= 0; i--) {
            const pastDate = new Date(today.getFullYear(), today.getMonth(), today.getDate() - i);
            const formattedDate = `${pastDate.getFullYear()}/${String(pastDate.getMonth() + 1).padStart(2, '0')}/${String(pastDate.getDate()).padStart(2, '0')}`;
            dates.push(formattedDate); 
        }
        return dates;
    }
    
      useEffect(() => {
        if (loading || error) {
            // ローディング中またはエラーがある場合、何もしない
            return;
          }
        const aggregatedData = labels.reduce((acc, date) => {
            acc[date] = {'0': 0, '1': 0, '2': 0}; // 各感情のインデックスに数値を初期化
            return acc;
        }, {});
        // console.log("data1",data)
        journalData.forEach(item => {
        // data.forEach(item => {
            if (aggregatedData.hasOwnProperty(item.date)) {
                // 数値を加算します
                aggregatedData[item.date][item.emojiIndex] += parseInt(item.duration, 10);
            }
        });

        const processedDataArray = Object.keys(aggregatedData).map(date => {
            return {
                date: date,
                '0': aggregatedData[date]['0'] / 60,
                '1': aggregatedData[date]['1'] / 60,
                '2': aggregatedData[date]['2'] / 60
            };
        });

        const initData = labels.reduce((acc, date) => {
            acc[date] = {'0': [], '1': [], '2': []};
            return acc;
        }, {});
    
        // console.log("data2",data)
        journalData.forEach(item => {
        // data.forEach(item => {
            if (initData.hasOwnProperty(item.date)) {
                initData[item.date][item.emojiIndex].push({
                    title: item.title,
                    date: item.date,
                    text: item.text,
                    person: item.person,
                    emotion: item.emotion,
                    duration: parseInt(item.duration, 10)
                });
            }
        });
    
        const newRawData = Object.keys(initData).map(date => ({
            date: date,
            data: initData[date] // 各感情インデックスごとのデータのリストを格納
        }));

        const updatedRawData = Object.keys(initData).map(date => ({
            date: date,
            data: {
                '0': initData[date]['0'].map(item => ({ ...item, duration: item.duration / 60 })),
                '1': initData[date]['1'].map(item => ({ ...item, duration: item.duration / 60 })),
                '2': initData[date]['2'].map(item => ({ ...item, duration: item.duration / 60 })),
            }
        }));
    
        setProcessedData(processedDataArray); // グラフデータ用
        setRawData(updatedRawData ); // ツールチップ表示用の詳細データ
    }, [journalData,loading,error]);

    useEffect(() => {
        if (scrollContainerRef.current) {
          const { scrollWidth, clientWidth } = scrollContainerRef.current;
          scrollContainerRef.current.scrollLeft = scrollWidth - clientWidth;
        }
      }, []); 
    
  
    const emotionColors = {
      0: 'lightyellow',  // 薄い黄色
      1: 'pink',         // ピンク
      2: 'lightblue'     // 青と灰色の中間色
    };

    // データセットの作成時に、各感情インデックスのデータをソート
    const dataset = ['0', '1', '2'].map(emojiIndex => {
        const dataPoints = processedData.map(item => item[emojiIndex]);
        const sortedDataPoints = dataPoints.sort(sortFunction);
        return {
            label: emojiIndex === '0' ? '😌' : emojiIndex === '1' ? '😀' : '😭',
            data: sortedDataPoints,
            backgroundColor: emotionColors[emojiIndex],
        };
    });

    const chartData = {
        labels,
        datasets: dataset,
        rawData: processedData  // ツールチップで使用するための詳細データを追加
    };

    const options = {
        responsive: true,
        maintainAspectRatio: false, 
        plugins: {
            tooltip: {
              enabled: true,
              mode: 'index',
              intersect: false,
              callbacks: createTooltipCallbacks(rawData),
              bodyFont: {
                size: 10, // ここでフォントサイズを指定
              },
              padding: {
                left: 10, // ここで左のパディングを指定
                right: 10, // ここで右のパディングを指定
              },
            },
            legend: {
                position: 'top', // これで凡例がチャートの上部に配置されます。
                align: 'end', // これで凡例の項目が右寄せになります。
              },
          },
        scales: {
          x: {
            stacked: true
          },
          y: {
            stacked: true
          }
        }
      };
      
      const chartWidthForWeb = labels.length * 50; 
      const chartWidthForMobile = labels.length * 30; 

      return (
        <Box overflowX="scroll" ref={scrollContainerRef}>
          <Box 
            minWidth={[`${chartWidthForMobile}px`,`${chartWidthForWeb}px`]}  
            height={{ base: "300px", md: "500px" }}
          >
            <Bar options={options} data={chartData} />
          </Box>
        </Box>
      );
  }
  
  export default RecordEmotionChart;