// RecordTable.js

import React from 'react';
import { Table, Thead, Tbody, Tr, Th, Td, Box,Button } from '@chakra-ui/react';
import * as XLSX from 'xlsx';
import { saveAs } from 'file-saver';

const RecordTable = ({ journalData }) => {
    // 絵文字と記号のオプション
    const emojiOptions = ['😌', '🙂', '😭'];
    const kanjiOptions = ['◻️', '☑️', '⏸️'];

    const downloadExcel = () => {
      const worksheet = XLSX.utils.json_to_sheet(journalData.map(item => ({
          'Kanji Index': kanjiOptions[item.kanjiIndex],
          'Date': item.date,
          'Title': item.title,
          'Text': item.text,
          'Person': item.person,
          'Emotion': item.emotion,
          'Start Time': item.startTime,
          'Duration': item.duration,
          'Emoji Index': emojiOptions[item.emojiIndex]
      })));
      const workbook = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(workbook, worksheet, "Journal Data");
      const excelBuffer = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });
      const blob = new Blob([excelBuffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8' });
      saveAs(blob, 'journalData.xlsx');
  };

  
    return (
      <Box overflowX="auto">
        <Box textAlign="right">
         <Button 
            onClick={downloadExcel} 
            colorScheme="teal" 
            mt="2%"
            mb="2%"
            mr="5%"
          >
            Download Excel
          </Button>
        </Box>
        <Table variant="simple">
          <Thead>
            <Tr>
              <Th>Kanji Index</Th>
              <Th>Date</Th>
              <Th>Title</Th>
              <Th>Text</Th>
              <Th>Person</Th>
              <Th>Emotion</Th>
              <Th>Start Time</Th>
              <Th>Duration</Th>
              <Th>Emoji Index</Th>
            </Tr>
          </Thead>
          <Tbody>
            {journalData.map((item) => (
              <Tr key={item.id}>
                <Td>{kanjiOptions[item.kanjiIndex]}</Td>
                <Td>{item.date}</Td>
                <Td>{item.title}</Td>
                <Td>{item.text}</Td>
                <Td>{item.person}</Td>
                <Td>{item.emotion}</Td>
                <Td>{item.startTime}</Td>
                <Td>{item.duration}</Td>
                <Td>{emojiOptions[item.emojiIndex]}</Td>
              </Tr>
            ))}
          </Tbody>
        </Table>
      </Box>
    );
  };
  

export default RecordTable;
