/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const getSettingInput = /* GraphQL */ `
  query GetSettingInput($id: ID!) {
    getSettingInput(id: $id) {
      id
      showTitle
      showMemo
      showEmotion
      showPerson
      showDate
      showStartTime
      showDuration
      userId
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listSettingInputs = /* GraphQL */ `
  query ListSettingInputs(
    $filter: ModelSettingInputFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listSettingInputs(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        showTitle
        showMemo
        showEmotion
        showPerson
        showDate
        showStartTime
        showDuration
        userId
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getSettingDisplay = /* GraphQL */ `
  query GetSettingDisplay($id: ID!) {
    getSettingDisplay(id: $id) {
      id
      showTitle
      showMemo
      showEmotion
      showPerson
      showStartTime
      showDuration
      userId
      titleOrder
      memoOrder
      emotionOrder
      personOrder
      startTimeOrder
      durationOrder
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listSettingDisplays = /* GraphQL */ `
  query ListSettingDisplays(
    $filter: ModelSettingDisplayFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listSettingDisplays(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        showTitle
        showMemo
        showEmotion
        showPerson
        showStartTime
        showDuration
        userId
        titleOrder
        memoOrder
        emotionOrder
        personOrder
        startTimeOrder
        durationOrder
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getPerson = /* GraphQL */ `
  query GetPerson($id: ID!) {
    getPerson(id: $id) {
      id
      name
      mbti
      relation
      memo
      userId
      display
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listPeople = /* GraphQL */ `
  query ListPeople(
    $filter: ModelPersonFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listPeople(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        mbti
        relation
        memo
        userId
        display
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getTitle = /* GraphQL */ `
  query GetTitle($id: ID!) {
    getTitle(id: $id) {
      id
      title
      userId
      display
      classification
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listTitles = /* GraphQL */ `
  query ListTitles(
    $filter: ModelTitleFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listTitles(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        title
        userId
        display
        classification
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getJournalData = /* GraphQL */ `
  query GetJournalData($id: ID!) {
    getJournalData(id: $id) {
      id
      text
      emojiIndex
      kanjiIndex
      type
      date
      userId
      photoUrl
      title
      duration
      startTime
      emotion
      person
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listJournalData = /* GraphQL */ `
  query ListJournalData(
    $filter: ModelJournalDataFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listJournalData(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        text
        emojiIndex
        kanjiIndex
        type
        date
        userId
        photoUrl
        title
        duration
        startTime
        emotion
        person
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
