import { useState, useEffect } from 'react';
import { Amplify, Auth } from 'aws-amplify';
import { fetchAuthSession, getCurrentUser } from 'aws-amplify/auth';
import amplifyconfig from './amplifyconfiguration.json';
import '@aws-amplify/ui-react/styles.css';
import { generateClient } from 'aws-amplify/api';
import { listJournalData } from './graphql/queries';

Amplify.configure(amplifyconfig);
const client = generateClient();

function useFetchJournalData() {
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [journalData, setJournalData] = useState([]);

  useEffect(() => {
    async function fetchJournalData() {
      setLoading(true);
      setError(null);
      try {
        const session = await fetchAuthSession(); // セッションを確認
        const userInfo = await getCurrentUser(); // ユーザー情報を取得
        const userId = userInfo.userId; // ユーザーIDを取

        const data = await client.graphql({ query: listJournalData }); // ジャーナルデータをクエリ
        const fetchedJournalData = data.data.listJournalData.items.filter(item => item.userId === userId); // ユーザーIDに基づいてフィルタ
        setJournalData(fetchedJournalData); // 状態を設定
      } catch (err) {
        setError(err); // エラーを状態に設定
        console.error("Error fetching data:", err);
      } finally {
        setLoading(false); // ローディング状態を解除
      }
    }

    fetchJournalData(); // データのフェッチを開始
  }, []);

  return { journalData, error, loading };
}

export default useFetchJournalData;

